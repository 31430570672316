<template>
  <div class="page-nation-af">
    
    <h3 class="title">核心指标</h3>
    <comCore ref="core" />
    
    <div class="box-list">
      <comTable ref="region" :query.sync="query" :dataList="regionData" @onClickRow="clickRegionRow"/>
    </div>
    <div class="box-list">
      <comTable ref="area" :query.sync="query" :dataList="areaData" @onClickRow="clickAreaRow"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm} from '@/api'
import {normalDate, toRange} from '@/utils/formatDate'
import pick from 'lodash.pick'
import actions from '@/api/action'
import CascadeFilter from '@/components/CascadeFilter'
import SelectSeller from '@/components/SelectSeller'
import {getRole} from '@/utils/getRole'
import NavBar from '@/components/afNavbarDealer.vue';
import comCore from './comCore.vue';
import comTable from './comTable.vue';


export default {
  name: '',
  props: {
  },
  components: {
    comCore,
    comTable,
    //CascadeFilter
  },
  data() {
    return {
      regionData: {},
      areaData: {},
    };
  },
  computed:{
  },
  methods: {
    clickRegionRow(record, index) {
      this.$router.push({name: 'afRegion', query: {...this.query,region_id:record.id}});
    },
    clickAreaRow(record, index) {
      this.$router.push({name: 'afArea', query: {...this.query,area_id:record.id}});
    },
    getDataBySearch(q) {
      this.query = {
        ...this.query,
        ...q,
      }
      this.onSearch();
    },
    onSearch(pageNum, pageSize) {
      this.pageNum = pageNum || this.pageNum;
      if (this.rangeDate) {
        const rd = normalDate(this.rangeDate);
        this.query.start_date = rd[0];
        this.query.end_date = rd[1];
      }

      const params = {...this.query};
      this.$refs.core && this.$refs.core.loadData(params);
      postForm('/api/bsa_board/region', params).then(res => {
        this.regionData = res.data;
      });
      postForm('/api/bsa_board/area', params).then(res => {
        this.areaData = res.data;
      });
      
    },
  },
  created() {
    this.role = getRole();

    const userInfo =  Vue.ls && Vue.ls.get('USER_INFO') || {};
    const {crop_id_default, brand_id: brand_id_default, company_type_ids} = userInfo;
    const comIds = company_type_ids && company_type_ids.split(',') || [];
    const {crop_id, brand_id, ...rest} = StringToNum(this.$router.currentRoute.query);
    this.query = {
      ...this.query,
      ...rest,
      crop_id: crop_id || crop_id_default || null,
      brand_id: brand_id || brand_id_default || null,
      company_type_id: comIds[0],
    }
  },  
  mounted () {
    this.onSearch();
  },
}
function StringToNum(obj){
  for(let i in obj){
    if(i!='start_date'&&i!="end_date"){
      obj[i] = (isNaN(Number(obj[i])) || !obj[i]) ? obj[i] : Number(obj[i]);
    }

  }
    return obj
}

</script>
<style scoped lang="less">
.page-nation-af {
}
</style>
